<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Vos bons de livraison appro sont t&eacute;l&eacute;chargeables dans cet
        &eacute;cran le lendemain de leur facturation par votre
        coop&eacute;rative.
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        Un m&ecirc;me bon de livraison peut contenir plusieurs lignes de ce
        tableau.
      </b-col>
    </b-row>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
export default {
  name: "HelpBlApproComponent",
  components: { HelpComponent },
};
</script>
