<template>
  <b-card class="mobile-card">
    <b-container v-b-toggle="`blappro_${item.idLigne}`">
      <b-row align-v="center">
        <b-col cols="auto" class="bg-light bg-left font-up" style="width: 80px">
          <div class="w-100">
            {{ jour }}/{{ mois }}<br />
            {{ annee }}
          </div>
        </b-col>
        <b-col>
          <b-row class="m-0" align-v="center">
            <b-col class="p-0">
              <div>{{ item.libelleProduit }}</div>
              <div class="font-down">
                <MontantComponent
                  :value="item.quantiteLivree"
                  :options="{ minimumFractionDigits: 0 }"
                ></MontantComponent>
                {{ item.unite }}
              </div>
              <b-collapse
                :id="`blappro_${item.idLigne}`"
                class="label font-down text-center"
                visible
              >
                <b-icon-chevron-down></b-icon-chevron-down>
                D&eacute;tails
              </b-collapse>
            </b-col>
            <b-col cols="auto" v-if="item.pieceJointe" @click.stop>
              <DownloadComponent
                :typeDocument="typeDocument"
                :targetId="item.numeroBon"
              ></DownloadComponent>
            </b-col>
          </b-row>
          <b-collapse :id="`blappro_${item.idLigne}`">
            <b-row class="m-0 mt-2 font-down">
              <b-col cols="4" class="p-0">Livraison</b-col>
              <b-col cols="8" class="p-0">
                {{ item.dateLivraison }}
              </b-col>
              <b-col cols="4" class="p-0">N° bon</b-col>
              <b-col cols="8" class="p-0">
                {{ item.numeroBon }}
              </b-col>
            </b-row>
            <b-row class="m-0 mt-2 font-down">
              <b-col cols="4" class="p-0">Prix unitaire</b-col>
              <b-col cols="8" class="p-0">
                <MontantComponent
                  :value="item.prixNet"
                  :options="{ minimumFractionDigits: 0 }"
                ></MontantComponent>
                &euro;/unit&eacute;
              </b-col>
              <b-col cols="4" class="p-0">Montant HT</b-col>
              <b-col cols="8" class="p-0">
                <MontantComponent
                  :value="item.montantHt"
                  :options="{ minimumFractionDigits: 0 }"
                ></MontantComponent>
                &euro;
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
import DownloadComponent from "../controls/DownloadComponent.vue";
export default {
  name: "LigneLivraisonApproCard",
  components: { MontantComponent, DownloadComponent },
  props: ["item"],
  data() {
    return {
      typeDocument: this.$store.state.expl.typesDocument.BON_LIVRAISON_APPRO,
    };
  },
  computed: {
    jour() {
      return this.item.dateLivraison.split("-")[2];
    },
    mois() {
      return this.item.dateLivraison.split("-")[1];
    },
    annee() {
      return this.item.dateLivraison.split("-")[0];
    },
  },
};
</script>
